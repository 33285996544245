import 'bootstrap';

/*const contactForm = document.querySelector('.contact-form');
let formOfAddress = document.getElementById('form_of_address');
let lastName = document.getElementById('last_name');
let firstName = document.getElementById('first_name');
let email = document.getElementById('email');
let phone = document.getElementById('phone');
let arrival = document.getElementById('arrival');
let departure = document.getElementById('departure');
let persons14 = document.getElementById('persons14');
let persons3 = document.getElementById('persons3');
let persons0 = document.getElementById('persons0');
let details = document.getElementById('details');
let privacyCheck = document.getElementById('privacy_check');

contactForm.addEventListener('submit', (e)=>{
    e.preventDefault();
    
    let formData = {
        formOfAddress: formOfAddress.value,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        arrival: arrival.value,
        departure: departure.value,
        persons14: persons14.value,
        persons3: persons3.value,
        persons0: persons0.value,
        details: details.value,
        privacyCheck: privacyCheck.value
    }

    let xhr = new XMLHttpRequest();
    xhr.open('POST', '/php/contact_form_submission.php', true);
    xhr.setRequestHeader('content-type', 'application/json');
    xhr.onload = function(){
        console.log(xhr.responseText);
        if(xhr.responseText == 'Success!'){
            alert('Email wurde gesendet!');
            formOfAddress.value = '';
            firstName.value = '';
            lastName.value = '';
            email.value = '';
            phone.value = '';
            arrival.value = '';
            departure.value = '';
            persons14.value = '';
            persons3.value = '';
            persons0.value = '';
            details.value = '';
            privacyCheck.value = '';
    } else {
        alert('Leider ist etwas schief gelaufen! Bitte versuchen Sie es erneut oder schreiben Sie eine Email an: info@baienhof.it');
    }}

    xhr.send(JSON.stringify(formData));

})*/